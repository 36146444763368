import React from "react";
import foguete from "../../img/foguete-lua.png";

import {
  PaginaInicial,
  ContainerPaginaInicial,
  InfosPaginaInicial,
  DecolePaginaInicial,
  PresencaPaginaInicial,
  DivFoguete,
} from "./style.js";

import Button from "../../Components/Button";
import Lottie from 'react-lottie';
import * as animationData from '../../Animations/rocket01.json'


const PagInicial = ({ Header }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <PaginaInicial id="home">
        {Header}
        <ContainerPaginaInicial>
          <div>
            <InfosPaginaInicial>
              <DecolePaginaInicial>DECOLE!</DecolePaginaInicial>
              <PresencaPaginaInicial>
                Sua história na internet
                <br /> começa com a gente!
              </PresencaPaginaInicial>

              <Button
                texto="QUERO TER PRESENÇA NA INTERNET"
                background="#fff"
                width="100%"
                color="#452372"
                fontWeight="700"
              />
            </InfosPaginaInicial>
          </div>
          <DivFoguete>
            {/* <img alt="some text"src={foguete} /> */}
            <Lottie options={defaultOptions}
              height={700}
              width={400}
              />
          </DivFoguete>
        </ContainerPaginaInicial>
      </PaginaInicial>
    </>
  );
};

export default PagInicial;
