import React from "react";

import { Parag } from "./style.js";

const Paragrafo = (props) => {
  return (

      <Parag color={props.color} fontWeight={props.fontWeight}>{props.texto}</Parag>
  );
};

export default Paragrafo;
