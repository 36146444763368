import React from "react";

import { LinhaCardRecursos } from "./style.js";
import Paragrafo from '../Paragrafo'

const CardChecked = (props) => {
  return (
    <>
      <LinhaCardRecursos className="linha-card-recursos">
        <div>
          <input type="checkbox" id="chk" checked="true" />
          <label for="chk" class="switch">
            <span class="slider"></span>
          </label>
        </div>
        <div>
          <Paragrafo color="#000" texto={props.texto}/>
        </div>
      </LinhaCardRecursos>
    </>
  );
};

export default CardChecked;
