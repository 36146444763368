import React from "react";
import ads from "../../img/ads.png";
import landing from "../../img/landing.png";
import www from "../../img/www.png";
import sistemas from "../../img/sistemas.png";
import automacao from "../../img/automacao.png";
import microfone from "../../img/microfone.png";

import {
  ContainerNossasSolucoes,
  ConteudoNossasSolucoes,
  TextoNossasSolucoes,
  TituloCardNossasSolucoes,
  ContainerTextosNossasSolucoes,
} from "./style.js";

import TituloSecao from "../../Components/TituloSecao";
import Paragrafo from "../../Components/Paragrafo";

const NossasSolucoes = ({ Header }) => {
  return (
    <>
      <ContainerNossasSolucoes>
        <TituloSecao textoTitulo="Nossas Solucoes" colorTitulo="#fff" />
        <ConteudoNossasSolucoes>
          <ContainerTextosNossasSolucoes>
            <TextoNossasSolucoes>
              <img alt='some value' src={ads} />
              <div>
                <TituloCardNossasSolucoes>
                  ANÚNCIOS ONLINE
                </TituloCardNossasSolucoes>
                <Paragrafo
                  texto="Compra de mídia em fontes de tráfego que seu cliente está"
                  color="#fff"
                />
              </div>
            </TextoNossasSolucoes>
            <TextoNossasSolucoes>
              <img alt='some value' src={landing} />
              <div>
                <TituloCardNossasSolucoes>
                  LANDING PAGES
                </TituloCardNossasSolucoes>
                <Paragrafo
                  texto="Páginas focadas em fazer o nosso cliente tomar uma ação especifica que nós desejamos"
                  color="#fff"
                />
              </div>
            </TextoNossasSolucoes>
            <TextoNossasSolucoes>
              <img alt='some value' src={www} />
              <div>
                <TituloCardNossasSolucoes>SITES</TituloCardNossasSolucoes>
                <Paragrafo
                  texto="Criação de site composto por variedade de paginas que informam sobre o produto, negócio, contato e tangencias"
                  color="#fff"
                />
              </div>
            </TextoNossasSolucoes>
            <TextoNossasSolucoes>
              <img alt='some value' src={sistemas} />
              <div>
                <TituloCardNossasSolucoes>SISTEMAS</TituloCardNossasSolucoes>
                <Paragrafo
                  texto="Desenvolvimento de programas para computadores e celulares com automações a medida dos clientes."
                  color="#fff"
                />
              </div>
            </TextoNossasSolucoes>
            <TextoNossasSolucoes>
              <img alt='some value' src={automacao} />
              <div>
                <TituloCardNossasSolucoes>AUTOMAÇÃO</TituloCardNossasSolucoes>
                <Paragrafo
                  texto="Geralmente conhecidos como bots essas automações têm o papel de substituir ações repetitivas, como, bots de conversas. "
                  color="#fff"
                />
              </div>
            </TextoNossasSolucoes>
          </ContainerTextosNossasSolucoes>
          <img alt='some value' src={microfone} className="microfone"/>
        </ConteudoNossasSolucoes>
      </ContainerNossasSolucoes>
    </>
  );
};

export default NossasSolucoes;
