import React, { useRef } from "react";
import { Navigation, ImagemHeader, TopHeader, NavButton } from "./style.js";

import logo_fg from "../../img/logo-fengari.png";

import { HashLink as Link } from "react-router-hash-link";

import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (

      <TopHeader>
        <Link smooth to="/">
          <ImagemHeader src={logo_fg} />
        </Link>
        <Navigation ref={navRef}>
          <Link to="/" onClick={showNavbar}>
            <h3>Home</h3>
          </Link>
          <Link to="#quemsomos" onClick={showNavbar}>
            <h3>Quem somos</h3>
          </Link>
          <Link to="#workflow" onClick={showNavbar}>
            <h3>Workflow</h3>
          </Link>
          <Link to="#formulario" onClick={showNavbar}>
            <h3 className="contato-texto">Entrar em contato</h3>
          </Link>

          <NavButton className=" nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </NavButton>
        </Navigation>
        <NavButton onClick={showNavbar}>
          <FaBars />
        </NavButton>
      </TopHeader>

  );
};

export default Header;
