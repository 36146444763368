import styled from "styled-components";

export const ContainerCondicaoPagamento = styled.div`
  background-color: #000;
  min-height: 70vh;
  padding-bottom: 3rem;
  @media only screen and (max-width: 1024px) {
    padding: 1rem;
  }
`;

export const BodyCondicaoPagamento = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    img{
      width:90% ;
    }
  }
`;

export const TextoCondicaoPagamento = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4rem;
  p {
    color: #fff;
    width: 70%;
    text-align: center;
  }

  @media (max-width: 1024px) {
    width:100% ;
    p {
    width: 90%;
    margin-top: 3rem;
  }
  }
`;
export const ChamadaCondicaoPagamento = styled.h2`
  text-align: center;
  margin-top: 3rem;
  width: 70%;
  color: #fff;
  span {
    color: var(--mainSecondaryColor);
  }

  @media (max-width: 1024px) {
    width: 90% ;
  }
`;
