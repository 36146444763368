import React from "react";

import {
  Recursos,
  CardRecursos,
} from "./style.js";

import CardChecked from "../../Components/CardChecked";
import TituloSecao from "../../Components/TituloSecao";


const PagRecursos = () => {
  return (
    <>
      <Recursos className="recursos">
        <TituloSecao
          textoTitulo="TODOS OS RECURSOS"
          colorTitulo="#000"
          textoSubtitulo="ESSENCIAIS E ADICIONAIS"
          colorSubtitulo="#fff"
        />
        <div></div>
        <CardRecursos className="card-recursos">
          <div>
            <CardChecked texto="Responsivo para mobile" />
            <CardChecked texto="Certificado SSL" />
            <CardChecked texto="Domínio personalizado" />
            <CardChecked texto="Chat online com WhatsApp" />
            <CardChecked texto="Otimização de SEO" />
            <CardChecked texto="Conformes com a lei LGPD" />
            <CardChecked texto="Hospedagem na medida do projeto" />
          </div>
        </CardRecursos>
      </Recursos>
    </>
  );
};

export default PagRecursos;
