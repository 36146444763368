import React from "react";
import luaRoxa from "../../img/lua-roxa.png";
import mao from "../../img/mao.png";
import sacola from "../../img/sacola.png";
import cem from "../../img/cem.png";
import ferramenta from "../../img/ferramenta.png";

import {
  ContainerSegundaPagina,
  BodySegundaPagina,
  InformacaoSergundaPagina,
  TextoSegundaPagina,
  AreaAtendimento,
  FooterQuemSomos,
} from "./style.js";

import Button from "../../Components/Button";
import TituloSecao from "../../Components/TituloSecao";
import Paragrafo from "../../Components/Paragrafo";

const PagInicial = () => {
  return (
    <ContainerSegundaPagina id="quemsomos">
      <TituloSecao
        textoTitulo="FENGARI.CO"
        colorTitulo="#503484"
        textoSubtitulo="O QUE É?"
      />
      <BodySegundaPagina>
        <InformacaoSergundaPagina>
          <img alt="some value" src={luaRoxa} />
        </InformacaoSergundaPagina>
        <InformacaoSergundaPagina>
          <TextoSegundaPagina>
            A definição literaria de Fengari vem da língua Grega que segnifica
            Lua, com base nessa definição decidimos seguir com esse nome, pois
            temos como missão elevar a presença e resultados digitais de nossos
            clientes até a Lua! <br />
            <br />
            Somos uma empresa voltada para o desenvolvimento de Software e
            Marketing, onde buscamos sempre com excelência trazer um serviço da
            melhor qualidade para nossos clientes. Atendemos
            <br />
            <br />
            um serviço da melhor qualidade para nossos clientes. Atendemos
            clientes de diversas áreas, como:
          </TextoSegundaPagina>
          <br />
          <br />
          <AreaAtendimento>
            <img alt="some value" src={cem} />
            <Paragrafo
              texto="Empresas que precisam de uma solução completa (Sites, Automações, tráfego pago...)."
              fontWeight="200"
            />
          </AreaAtendimento>
          <AreaAtendimento>
            <img alt="some value" src={sacola} />
            <Paragrafo
              texto="Lojas fisicas ou onlines que precisam de um marketing estrátegico para vender mais."
              fontWeight="200"
            />
          </AreaAtendimento>
          <AreaAtendimento>
            <img alt="some value" src={mao} />
            <Paragrafo
              texto="Empresas de marketing que precisam vender soluções de software e não tem mão de obra suficiente."
              fontWeight="200"
            />
          </AreaAtendimento>
          <AreaAtendimento>
            <img alt="some value" src={ferramenta} />
            <Paragrafo
              texto="Empresas que necessitam de manutenções em softwares."
              fontWeight="200"
            />
          </AreaAtendimento>
        </InformacaoSergundaPagina>
      </BodySegundaPagina>
      <FooterQuemSomos>
        <Paragrafo texto="Entre outros modelos de negócio, quer saber se assim como esses negócios o seu também pode ser atendido? Clique no botão e converse com nosso time!" />
          <Button
            texto="Quero saber mais sobre os serviços"
            width="80%"
            background="#503484"
            color="#fff"
          />
      </FooterQuemSomos>
    </ContainerSegundaPagina>
  );
};

export default PagInicial;
