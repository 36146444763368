import styled from "styled-components";
import background from "../../img/backgroundNossasSolucoes.jpg";

export const ContainerNossasSolucoes = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${background});
  padding-bottom: 3rem;

  @media (max-width: 1024px) {
  }
`;
export const ConteudoNossasSolucoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  @media (max-width: 1024px) {
    .microfone {
      display:none;
    }
  }
`;
export const ContainerTextosNossasSolucoes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media (max-width:1024px){
    width: 100%;
  }
`;

export const TextoNossasSolucoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width:1024px){
    flex-direction: column;
    text-align: center;
    img{
      width:50% ;
      margin: 2rem 0;
    }
  }
`;
export const TituloCardNossasSolucoes = styled.h3`
  color: #fff;
  margin-left: 1rem;
`;
