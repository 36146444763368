import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props) => props.width || "100%"};
  justify-content: center;
`;

export const Btn = styled.a`
  background: #4bed68;
  border: none;
  min-height: 2.5rem;
  text-decoration: none;
  width: ${(props) => props.width};
  border-radius: 0.5rem;
  color: #000;
  font-size: 1.2rem;
  margin-top: ${(props) => props.marginTop || "2rem"};
  font-weight: normal;
  padding: 0.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 90%;
    text-align: center;
  }
`;
