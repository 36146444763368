import styled from "styled-components";

export const ContainerSegundaPagina = styled.div`
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


`;
export const HeadSegundaPagina = styled.div`
  text-align: center;
`;

export const BodySegundaPagina = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column ;
  }
`;

export const InformacaoSergundaPagina = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    img{
      width:40% ;
    }
    
  }
`;
export const TextoSegundaPagina = styled.h3`
  width: 90%;
  font-weight: 400;
  line-height: 1.5;
`;

export const FooterQuemSomos = styled.div`
  width: 60% ;
  a{
    text-decoration: none;
  }
  @media (max-width:1024px){
    width:90% ;
  }
`

export const AreaAtendimento = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-bottom: 2rem;
  @media (max-width:1024px){
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`
