import './App.css';
import Header from './Pages/Header'
import PagInicial from './Pages/PagInicial'
import PagWorkFlow from './Pages/PagWorkflow'
import PagRecursos from './Pages/PagRecursos'
import SegundaPagina from './Pages/QuemSomos'
import AnuncioOnline from './Pages/AnuncioOnline'
import NossasSolucoes from './Pages/NossasSolucoes'
 import CondicaoPagamento from './Pages/CondicaoPagamento'
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
    <PagInicial Header={<Header/>}/>
    <NossasSolucoes/>
    <CondicaoPagamento/>
    <SegundaPagina/>
    <PagWorkFlow />
    <PagRecursos />
    <AnuncioOnline/>
    </Router>
  );
}

export default App;
