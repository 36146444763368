import styled from "styled-components";

export const CabecalhoSecao = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem ;
`;

export const Titulo = styled.h1`
  color: ${(props) => props.colorTitulo || "#000"};
  font-weight: 700;
`;

export const Subtitulo = styled.h2`
  color: ${(props) => props.colorSubtitulo || "#000"};

`;
