import styled from "styled-components";

export const ContainerAnuncioOnline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 80%;
  margin-bottom: 3rem;
  a{
    text-decoration: none;
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
  }
`;

export const ConteudoAnuncioOnline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px){
    flex-direction: column;
  }
`;
export const ImagemAnuncioOnline = styled.div`
  /* width: 40%;*/
  img {
    margin-top: 2rem;
  }
  @media (max-width: 1024px){
    img{
      width: 100%;
    }
  }
`;

export const TextosAnuncioOnline = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width:1024px){
    width: 90%; 
  }
`;

export const LinhaAnuncioOnline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width:1024px){
    margin-top: 1rem;
    align-items: start;
    justify-content: flex-start;
   img{
    width:15% ;
   }
   p{
    /* text-decoration: underline;
    text-decoration-color: var(--mainSecondaryColor); */
    font-size: 1rem ;
    text-align: center;
   }
   
  }
`;

// export const ConteudoAnuncioOnline = styled.div`

// `
