import React from "react";

import { CabecalhoSecao, Titulo, Subtitulo } from "./style.js";

const TituloSecao = (props) => {
  if (props.ordemTitulos === "invertido") {
    return (
      <CabecalhoSecao>
        <Subtitulo colorSubtitulo={props.colorSubtitulo}>
          {props.textoSubtitulo}
        </Subtitulo>
        <Titulo colorTitulo={props.colorTitulo}>{props.textoTitulo}</Titulo>
      </CabecalhoSecao>
    );
  } else {
    return (
      <CabecalhoSecao>
        <Titulo colorTitulo={props.colorTitulo}>{props.textoTitulo}</Titulo>
        <Subtitulo colorSubtitulo={props.colorSubtitulo}>
          {props.textoSubtitulo}
        </Subtitulo>
      </CabecalhoSecao>
    );
  }
};

export default TituloSecao;
