import React from "react";
import { ButtonContainer, Btn } from "./style.js";

const Buttton = (props) => {
  return (
    <ButtonContainer>
      <Btn
      className="track-tag-manager"
        width={props.width}
        fontWeight={props.fontWeight}
        marginTop={props.marginTop}
        href="https://api.whatsapp.com/send/?phone=5517997191137&text=Quero%20ter%20a%20presen%C3%A7a%20online%20da%20minha%20empresa!&type=phone_number&app_absent=0"
        target="_blank"
      >
        {props.texto}
      </Btn>
    </ButtonContainer>
  );
};

export default Buttton;
