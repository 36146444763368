import React from "react";
import um from "../../img/um.png";
import dois from "../../img/dois.png";
import tres from "../../img/tres.png";
import quatro from "../../img/quatro.png";
import cinco from "../../img/cinco.png";

import TituloSecao from "../../Components/TituloSecao";

import {
  WorkFlow,
  CardsWorkFlow,
  Card,
  HeadCardWorkFlow,
  TituloCardWorkFlow,
  TextoCardWorkFlow,
} from "./style.js";

const PagWorkFlow = () => {
  return (
    <WorkFlow id="workflow">
      <TituloSecao
        textoTitulo="WORK FLOW"
        colorTitulo="#6441a5"
        textoSubtitulo="DOS PROJETOS"
        colorSubtitulo="#fff"
      />
      <CardsWorkFlow>
        <Card>
          <HeadCardWorkFlow>
            <img alt="some text" src={um} />
            <TituloCardWorkFlow>Briefing</TituloCardWorkFlow>
          </HeadCardWorkFlow>
          <TextoCardWorkFlow>
            É o primeiro passo para uma execução de qualidade, pois é aqui que
            determinamos as etapas que seguiremos rumo ao sucesso. <br />
            <br />
            Fazemos algumas perguntas essenciais para o decorrer do projeto
            assim decidimos qual o caminho ideal para desenvolver a ideia
            exclusivamente de cada necessidade.
          </TextoCardWorkFlow>
        </Card>
        <Card>
          <HeadCardWorkFlow>
            <img alt="some text" src={dois} />
            <TituloCardWorkFlow>Projeto</TituloCardWorkFlow>
          </HeadCardWorkFlow>
          <TextoCardWorkFlow>
            Na etapa de apresentação do projeto mostramos ao cliente a ideia que
            desenvolvemos baseada no briefing preenchido na etapa anterior.
            <br />
            <br />
            Explicamos cada passo da execução e quais são as metas do projeto.
            Para assim entregar o resultado alinhando todas as espectativas.
          </TextoCardWorkFlow>
        </Card>

        <Card>
          <HeadCardWorkFlow>
            <img alt="some text" src={tres} />
            <TituloCardWorkFlow>Design</TituloCardWorkFlow>
          </HeadCardWorkFlow>
          <TextoCardWorkFlow>
            Embasado em toda a discussão anterior e com as espectativas
            alinhadas para o projeto.
            <br />
            <br /> Desenvolvemos o design da pagina com as caracteristicas de
            cada nicho e a identidade visual da empresa em ferramentas onde é
            possivel uma pré visualização de como o projeto vai ser finalizado.
          </TextoCardWorkFlow>
        </Card>
        <Card>
          <HeadCardWorkFlow>
            <img alt="some text" src={quatro} />
            <TituloCardWorkFlow>Código</TituloCardWorkFlow>
          </HeadCardWorkFlow>
          <TextoCardWorkFlow>
            Depois do Design pronto, nosso programador começa o trabalho.
            <br />
            <br /> Onde de fato é feita todo o desenvolvimento de todas as partes
            das funções e tecnologias da nossa página.
            <br />
            <br /> Executando todos os passos anteriormente estipulados.
          </TextoCardWorkFlow>
        </Card>
        <Card>
          <HeadCardWorkFlow>
            <img alt="some text" src={cinco} />
            <TituloCardWorkFlow>Aprovação</TituloCardWorkFlow>
          </HeadCardWorkFlow>
          <TextoCardWorkFlow>
            Aqui é onde é apresentada o projeto final ao cliente para a devida
            aprovação.
            <br />
            <br /> Caso o cliente desaprove alguma parte da finalização voltamos
            ao briefing para entender como melhorar a devida parte e refazemos
            do gosto do cliente.
          </TextoCardWorkFlow>
        </Card>
      </CardsWorkFlow>
    </WorkFlow>
  );
};

export default PagWorkFlow;
