import styled from "styled-components";

export const WorkFlow = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  background: linear-gradient(180deg, #212121 0%, #353535 100%);

  @media (max-width: 1024px) {
    padding-bottom: 3rem;
  }
`;
export const HeadCardWorkFlow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  img {
    width: 3rem;
    margin: 1rem;
  }
`;
export const CardsWorkFlow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  justify-content: center;
  min-height: 60vh;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Card = styled.div`
  border: 1px solid #8c25ee;
  border-radius: 1rem;
  width: 17%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem;
  margin: 0 1rem;

  @media (max-width: 1024px) {
    width: 80%;
    min-height: 20rem;
    margin: 1.5rem 0;
  }
`;

export const TituloCardWorkFlow = styled.h2`
  color: #fff;
`;
export const TextoCardWorkFlow = styled.p`
  color: #fff;
  /* text-align: justify ; */
`;
