import styled from "styled-components";

export const LinhaCardRecursos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem;


  #chk {
    display: none;
  }
  .switch {
    position: relative;
    background-color: #777;
    width: 3.5rem;
    height: 1.7rem;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.5s ease-in-out;
  }
  .slider {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.5s ease-in-out;
  }
  #chk:checked ~ .switch {
    background-color: green;
  }

  #chk:checked ~ .switch .slider {
    transform: translateX(1.9rem);
  }
`;
