import styled from "styled-components";

export const Recursos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  background: #503484;
  padding-bottom:3rem ;
`;

export const CardRecursos = styled.div`
  min-height: 35rem;
  max-width: 25rem;
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;

  @media (max-width: 1024px) {
    margin: 1.5rem;
  }
`;
