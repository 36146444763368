import React from "react";
import {
  ContainerCondicaoPagamento,
  BodyCondicaoPagamento,
  TextoCondicaoPagamento,
  ChamadaCondicaoPagamento,
} from "./style.js";

import catao from "../../img/catao.png";

import TituloSecao from "../../Components/TituloSecao";
import Paragrafo from "../../Components/Paragrafo";
import Button from "../../Components/Button";

import Lottie from 'react-lottie';
import * as animationData from '../../Animations/credit-card02.json'

const CondicaoPagamento = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <ContainerCondicaoPagamento>
      <TituloSecao
        textoSubtitulo="CONDIÇÕES DE"
        colorSubtitulo="var(--mainSecondaryColor)"
        textoTitulo="PAGAMENTO"
        colorTitulo="var(--mainSecondaryColor)"
        ordemTitulos="invertido"
      />

      <BodyCondicaoPagamento>
        {/* <img alt="some text"src={catao} /> */}
        <Lottie options={defaultOptions}
              height={550}
              width={700}
              />
        <TextoCondicaoPagamento>
          <Paragrafo
            texto="Saindo novamente dos padrões 
do mercado, nós decidimos fazer
algumas condições especiais para
nossos clientes."
            color="#fff"
            fontWeight="200"
          />
          <ChamadaCondicaoPagamento>
            Entrada de apenas <span>20%</span> e parcelamento em até{" "}
            <span>5x*</span>
          </ChamadaCondicaoPagamento>
          <Paragrafo texto="Cartão ou Boleto" color="#fff" fontWeight="200" />
        </TextoCondicaoPagamento>
      </BodyCondicaoPagamento>
      <Button
        texto="Quero saber mais sobre os serviços"
        width="70%"
        background="#503484"
        color="#fff"
      />
    </ContainerCondicaoPagamento>
  );
};

export default CondicaoPagamento;
