import React from "react";
import {
  ContainerAnuncioOnline,
  ConteudoAnuncioOnline,
  TextosAnuncioOnline,
  LinhaAnuncioOnline,
  ImagemAnuncioOnline,
} from "./style.js";

import check from "../../img/check.png";
import celular from "../../img/imagem-celular.png";

import TituloSecao from "../../Components/TituloSecao";
import Paragrafo from "../../Components/Paragrafo";
import Button from "../../Components/Button";

const AnuncioOnline = () => {
  return (
    <ContainerAnuncioOnline>
      <TituloSecao
        textoTitulo="ANÚNCIOS ONLINE"
        colorTitulo="var(--mainSecondaryColor)"
      />
      <Paragrafo texto="Especialistas em compra de mídia foco nas principais fontes de anúncios, o trabalho do time de tráfego é colocar a oferta/comunicação certa na frente das pessoas certas e assim trazer o resultado desejado de cada cliente." />

      <ConteudoAnuncioOnline>
        <ImagemAnuncioOnline>
          <img alt="some text" src={celular} />
        </ImagemAnuncioOnline>
        <TextosAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Análise de mercado;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Análise de concorrentes;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Estruturação de planejamento tático;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Análise do processo somercial;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Estruturação de campanhas;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Otimização semanal de campanhas;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Relatórios semanais;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Atedimento dedicado;" />
          </LinhaAnuncioOnline>
          <LinhaAnuncioOnline>
            <img alt="some text" src={check} />
            <Paragrafo texto="Consultoria em posicionamento digital;" />
          </LinhaAnuncioOnline>
        </TextosAnuncioOnline>
      </ConteudoAnuncioOnline>

      <Button
        texto="Quero saber mais sobre os serviços"
        width="70%"
      />
    </ContainerAnuncioOnline>
  );
};

export default AnuncioOnline;
