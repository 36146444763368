import styled from "styled-components";

export const PaginaInicial = styled.div`
  min-height: 100vh;
  background: linear-gradient(303.34deg, #2a0845 54.02%, #6441a5 99.2%);

  @media (max-width: 1024px) {
    padding-bottom: 2rem;
  }
`;
export const ContainerPaginaInicial = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (max-width: 1024px) {
    margin-top: 4rem;
    justify-content: center;
  }
`;
export const InfosPaginaInicial = styled.div`
  @media (max-width: 1024px) {
    align-items: center;
  }
`;
export const DecolePaginaInicial = styled.h1`
  margin: 0;
  background: white;
  display: inline-block;
  font-size: 7rem;
  font-weight: 900;
  color: #452372;
  padding:0 1rem;

  @media (max-width: 1024px) {
    font-size: 4rem;
  }
`;
export const PresencaPaginaInicial = styled.h2`
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
  margin-top: 2rem;
  @media (max-width: 1024px) {
    margin-bottom: 3rem;
    font-size:1.7rem ;
  }
`;


export const DivFoguete = styled.div`
  max-width: 60%;
  @media (max-width: 1024px) {
    display: none;
  }
`;
